.carouselCard {
  padding: 16px 32px;
  border: 4px solid rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  &__flavor {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    margin-block: 16px;
  }
  &__label {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.92em;
    opacity: 0.6;
    + h4 {
      margin-bottom: 0;
    }
  }
  .ant-carousel .slick-list .slick-slide {
    pointer-events: auto;
  }
}
// TODO 커스텀화 필요
.ant-carousel .slick-dots-bottom.ismobile {
  bottom: -24px;
  > li button {
    background-color: #1890ff;
  }
}
