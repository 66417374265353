.linkButton {
  padding: 0;
  border: 0;
  height: auto;
}

.ant-btn-icon-only {
  display: flex;
  justify-content: center;
  align-items: center;
}
