.ant-layout-content {
  padding: 24px;
  overflow: auto;
}
.ant-layout-header {
  padding: 0 24px;
}

.ant-layout-sider,
.ant-layout-sider-trigger {
  box-shadow: rgb(225, 227, 230) -1px 0px 0px 0px inset;
}

.allCenter {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
